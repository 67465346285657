import React from 'react'
import Header from '../components/Header/Header'
import ItemsHome from './ItemsHome'
import HomeFooter from '../components/Footer/HomeFooter'

const SurgicalItems = () => {
  return (
    <div>
        <Header />
        <ItemsHome />
        <HomeFooter />
    </div>
  )
}

export default SurgicalItems