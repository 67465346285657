import React from 'react'
import Header from '../components/Header/Header'
import BlogPro from './BlogPro'
import HomeFooter from '../components/Footer/HomeFooter'

const Products = () => {
  return (
    <div>
        <Header />
        <BlogPro />
        <HomeFooter />
    </div>
  )
}

export default Products;