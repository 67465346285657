import React from 'react'
import './Text.css'
const Test = () => {
  return (
    <div>
      <div className="typing-effect">
        <div className="typing-text">
          <div className="typing-animation">
            <span>Treatment for All! </span> <br />
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Test