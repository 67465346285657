import React from "react";
import './Text.css'
const Test2 = () => {
  return (
    <div>
      <div className="typing-effect1">
        <div className="typing-text">
          <div className="typing-animation1">
            <span>MEDISURE PHARMA </span>
          </div>
        </div>
        
      </div>
      
    </div>
  );
};

export default Test2;
