import React from 'react'
import Header from '../Header/Header'
import HomeFooter from '../Footer/HomeFooter'
import BannerImages from '../BannerImages/BannerImages'
import BlogPro from '../../pages/BlogPro'
import ItemsHome from '../../pages/ItemsHome'
import Carousel from '../Carousel/Carousel'
import Test from './Test'
import Test2 from './Test2'
import CarouselHandle from '../CarouselHandle/CarouselHandle'
import MapLoaction from '../MapLocation/MapLoaction'
const Home = () => {
  return (
    <div className='overflow-hidden'>
        <Header />
        <Carousel />
        <div className='mt-5'>
        <BlogPro />
       
        </div>
        <ItemsHome />
        <CarouselHandle />
        <MapLoaction />
        <HomeFooter />
    </div>
  )
}

export default Home